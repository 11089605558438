import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'integral', loadChildren: './integral/integral.module#IntegralPageModule' },
  { path: 'balance', loadChildren: './balance/balance.module#BalancePageModule' },
  { path: 'acMember', loadChildren: './acivate-membership/acivate-membership.module#AcivateMembershipPageModule' },
  { path: 'coupon', loadChildren: './coupon/coupon.module#CouponPageModule' },
  { path: 'show-qr-code', loadChildren: './show-qr-code/show-qr-code.module#ShowQrCodePageModule' },
  { path: 'conpon-unbound', loadChildren: './conpon-unbound/conpon-unbound.module#ConponUnboundPageModule' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
